<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Payments') }}
    </PageTitle>
    <Loader v-if="usersPaymentsDataLoading" class="h-100"/>
    <t-card v-else class="w-full">
      <div v-if="usersPaymentsData">
        <DataTable :data="usersPaymentsData" :headers="tableHeaders" custom-actions>
          <template #username="{ item }">
            <div
              class="cursor-pointer hover:opacity-50"
              @click="showProfileModal(item.username)"
            >
              {{  item.removedAt? trans(item.name) : item.name.length<20?item.name: item.name.substring(0,17)+'...'}}
            </div>
          </template>
          <template #packageName="{ item }">
            {{ trans(item.packageName) }}
          </template>
        </DataTable>
        <t-pagination
          class="mt-3"
          variant="posts"
          :total-items="pagination.total"
          :per-page="pagination.perPage"
          :limit="5"
          v-model="currentPage"
          @change="next"
        ></t-pagination>
      </div>
    </t-card>

    <!-- User profile -->
    <Modal
      hide-footer
      v-if="isShowProfileModal"
      :show-modal="isShowProfileModal"
      @clicked="isShowProfileModal = false"
      variant="profileAdmin"
    >
      <template #content>
        <ProfileGuest :username="profileUsername"/>
      </template>
    </Modal>

  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import ProfileGuest from "@/views/content/profile/ProfileGuest";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "UsersList",
  components: {
    AdminPageFrame,
    Button,
    DataTable,
    Loader,
    Modal,
    PageTitle,
    Pagination,
    ProfileGuest,
  },
  data() {
    return {
      isShowProfileModal: false,
      profileUsername:'',
      tableHeaders: [
        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Username',
          value: 'username',
        },
        {
          name: 'LABEL:Subscription',
          value: 'packageName',
        },
        {
          name: 'LABEL:CreatedAt',
          value: 'createdAt',
        },
        {
          name: 'LABEL:Price',
          value: 'totalAmount',
        },
        {
          name: 'LABEL:DiscountCode',
          value: 'discountCode',
        },
        {
          name: 'LABEL:Partner',
          value: 'discountCodePartner',
        },
        {
          name: 'LABEL:PaymentStatus',
          value: 'paymentStatus',
        },
      ],
      currentPage: 1,
      showPaymentsUserModalId: null,
    }
  },
  computed: {
    ...mapGetters('adminUsers', ['usersPaymentsData', 'usersPaymentsDataLoading', 'pagination']),
  },
  methods: {
    ...mapActions('adminUsers', ['fetchUsersPaymentsRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchUsersPaymentsRequest({
        page: val,
      });
    },

    showProfileModal(username) {
      this.isShowProfileModal = true;
      this.profileUsername = username;
    },

  },
  mounted() {
    this.fetchUsersPaymentsRequest({page: this.currentPage});
  }
}
</script>
